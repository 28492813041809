import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import _ from "lodash";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { NonOpsConstant } from "presentation/constant/NonOps/NonOpsConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useNonOpsHeaderVM } from "presentation/hook/NonOps/useNonOpsHeaderVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const NonOpsHeaderTitleBar: React.FC = () => {
    const [nonOpsHdrState] = useNonOpsHeaderTracked();
    const nonOpsHdrVM = useNonOpsHeaderVM();
    const HDR_STATE_CONST = ChargeConstantCollection.manHdrState;

    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);


    const currentRows = nonOpsHdrState.selectedManualChargeHeaderRows;

    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate, allowCreate, allowDelete } = anainfoState;
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);

    const handleSearchClick = useCallback(() => {
        nonOpsHdrVM.onSearchClick();
    }, [nonOpsHdrVM]);


    const handleAdd = useCallback(() => {
        nonOpsHdrVM.onAdd();
    }, [nonOpsHdrVM]);

    // const handleDetail = useCallback(() => {
    //     if(selectedRowsCount === 1){
    //         nonOpsHdrVM.onSelectedChargeHeader(currentRows[0])
    //         nonOpsHdrVM.onDetail(currentRows[0]);
    //     }else{
    //         messageBarVM.showError('Please select a record!');  
    //     }        

    // }, [nonOpsHdrVM, messageBarVM, currentRows, selectedRowsCount]);   


    const handleAccTml = useCallback(() => {

        if (_.isEmpty(currentRows)) {
            messageBarVM.showError('Please select a record.')
        } else {
            if (currentRows?.some(row => row.hdrState !== HDR_STATE_CONST.NIL)) {
                messageBarVM.showError('The selected non-ops charge is not in "NIL" state.')
            } else {
                setIsLoading(true);
                nonOpsHdrVM.onAccTml(currentRows).then(() => {
                    nonOpsHdrVM.onSearch(nonOpsHdrState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then(() => {
                        setIsLoading(false);
                    }).catch((error) => {
                        setIsLoading(false)
                    })
                }).catch((error) => {
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                })
            }
        }


    }, [HDR_STATE_CONST.NIL, currentRows, nonOpsHdrState.searchCriteria, nonOpsHdrVM, messageBarVM]);

    const handleResumeAccTml = useCallback(() => {
        if (_.isEmpty(currentRows)) {
            messageBarVM.showError('Please select a record.')
        } else {
            if (currentRows?.some(row => row.hdrState !== HDR_STATE_CONST.TML)) {
                messageBarVM.showError('The selected non-ops charge is not in "TML" state.');
            } else {
                setIsLoading(true);
                nonOpsHdrVM.onResumeAccTml(currentRows).then(() => {
                    nonOpsHdrVM.onSearch(nonOpsHdrState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then(() => {
                        setIsLoading(false)
                    }).catch((error) => {
                        setIsLoading(false)
                    })
                }).catch((error) => {
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                })
            }
        }
    }, [HDR_STATE_CONST.TML, currentRows, nonOpsHdrState.searchCriteria, nonOpsHdrVM, messageBarVM]);


    const handleConfirm = useCallback(() => {
        if (_.isEmpty(currentRows)) {
            messageBarVM.showError('Please select a record.')
        } else {
            if (currentRows?.some(row => row.hdrState !== HDR_STATE_CONST.NIL)) {
                messageBarVM.showError('The selected non-ops charge is not in "NIL" state.')
            } else {
                setIsLoading(true);
                nonOpsHdrVM.onConfirm(currentRows).then((res) => {
                    if (res && !res.success) {
                        messageBarVM.showError(res.data as string)
                    }
                    nonOpsHdrVM.onSearch(nonOpsHdrState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then(() => {
                        setIsLoading(false)
                    }).catch((error) => {
                        setIsLoading(false)
                    })
                }).catch((error) => {
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                });
            }
        }
    }, [HDR_STATE_CONST.NIL, currentRows, nonOpsHdrState.searchCriteria, nonOpsHdrVM, messageBarVM]);

    const handleUnConfirm = useCallback(() => {
        if (_.isEmpty(currentRows)) {
            messageBarVM.showError('Please select a record.')
        } else {
            if (currentRows?.some(row => row.hdrState !== HDR_STATE_CONST.CFM)) {
                messageBarVM.showError('The selected non-ops charge is not in "CFM" state.')
            } else {
                setIsLoading(true);
                nonOpsHdrVM.onUnConfirm(currentRows).then(() => {
                    nonOpsHdrVM.onSearch(nonOpsHdrState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then(() => {
                        setIsLoading(false)
                    }).catch((error) => {
                        setIsLoading(false)
                    })
                }).catch((error) => {
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                });
            }
        }
    }, [HDR_STATE_CONST.CFM, currentRows, nonOpsHdrState.searchCriteria, nonOpsHdrVM, messageBarVM]);


    const handleDelete = () => {
        if (_.isEmpty(currentRows)) {
            messageBarVM.showError('Please select a record.')
        } else {
            if (currentRows?.some(row => row.hdrState !== HDR_STATE_CONST.NIL && row.hdrState !== HDR_STATE_CONST.STD)) {
                messageBarVM.showError('The selected non-ops is not in "NIL" state.')
            } else {
                setIsShowDelete(false);
                setIsLoading(true);
                nonOpsHdrVM.onDelete(currentRows).then((data) => {
                    nonOpsHdrVM.onSearch(nonOpsHdrState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then(() => {
                        setIsLoading(false)
                    }).catch((error) => {
                        setIsLoading(false)
                    }).finally(() => {
                        if (data && data["deleteNonOpsChargeFail"]) {
                            messageBarVM.showError(data["deleteNonOpsChargeFail"]?.toString());
                        } else {
                            messageBarVM.showSuccess(MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace(
                                "{operationDone}", WorkspaceConstant.Common.BUTTON_DELETE));
                        }
                        setIsLoading(false);
                    })
                }).catch((error) => {
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                });
            }
        }
    }

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const handleStdBillClick = () => {
        if (_.isEmpty(currentRows)) {
            messageBarVM.showError('Please select a record.')
        } else {
            if (currentRows?.some(row => row.hdrState !== HDR_STATE_CONST.STD)) {
                messageBarVM.showError('The selected non-ops is not in "STD" state.')
            } else {
                setIsLoading(true);
                nonOpsHdrVM.onStdBill(currentRows).then(() => {
                    nonOpsHdrVM.onSearch(nonOpsHdrState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then(() => {
                        setIsLoading(false)
                    }).catch((error) => {
                        setIsLoading(false)
                    })
                }).catch((error) => {
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                });
            }
        }
    }

    const handleReprintClick = useCallback(() => {
        nonOpsHdrVM.onShowPrintModal();
    }, [nonOpsHdrVM]);

    const isDisable = () => {
        if (currentRows && currentRows.length > 0) {
            return false;
        }
        return true;
    }

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{NonOpsConstant.Header.NON_OPS_STD_BILL}</HeaderTitle>
        {/* <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: NonOpsConstant.Header.NON_OPS_STD_BILL }]}></HPHBreadcrumb>
        </Breadcrumb> */}
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
            {(allowCreate) && <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />}
            {(allowUpdate || allowDelete) && <div className="add-seperator" />}
            {(allowUpdate) && <><HPHButton label={WorkspaceConstant.Common.BUTTON_ACCTML} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleAccTml} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_RESUMEACCTML} size={'Small'} disabled={isDisable()} theme={'Secondary'} onClick={handleResumeAccTml} />
                <div className="add-seperator" /></>}
            {<HPHButton label={WorkspaceConstant.Common.BUTTON_REPRINT} disabled={false} size={'Small'} theme={'Secondary'} onClick={handleReprintClick} />}
            {(allowDelete) && <HPHButton label={WorkspaceConstant.Common.BUTTON_DELETE} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} />}
            {(allowUpdate) && <><HPHButton label={WorkspaceConstant.Common.BUTTON_GENNONOPS} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleStdBillClick} />
                <div className="add-seperator" />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_UNCONFIRM} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleUnConfirm} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CONFIRM} disabled={isDisable()} size={'Small'} theme={'Primary'} onClick={handleConfirm} />
            </>}
            {/*<IconButton fileName='Icon-copy' disabled={false} size='medium' toolTipText={'Detail'} toolTipArrow={false} onClick={handleDetail} />
            <div className="add-seperator"/>
            <IconButton fileName='Icon-alert-circle' disabled={false} size='medium' toolTipText={'Acc Tml'} toolTipArrow={false} onClick={handleAccTml} />
            <IconButton fileName='Icon-reset' disabled={false} size='medium' toolTipText={'Resume Acc Tml'} toolTipArrow={false} onClick={handleResumeAccTml} /> 
            <div className="add-seperator"/>
            <IconButton fileName='Icon-tick' disabled={false} size='medium' toolTipText={'Confirm'} toolTipArrow={false} onClick={handleConfirm} />
            <IconButton fileName='Icon-recover' disabled={false} size='medium' toolTipText={'UnConfirm'} toolTipArrow={false} onClick={handleUnConfirm} />*/}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${NonOpsConstant.Header.NON_OPS}`}
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {nonOpsHdrState.forceRefresh && <></>}
    </Sidebarheader>
}

export default memo(NonOpsHeaderTitleBar);