import { CurrencyEntity } from "domain/entity/ExchangeRate/CurrencyEntity";
import { ExchangeRateEntity } from "domain/entity/ExchangeRate/ExchangeRateEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ExchangeRateRepository } from "./ExchangeRateRepo";

export const ExchangeRateRepoImpl = (): ExchangeRateRepository => {
    const url = '/v1/exchangeRateSearchForComboBox';

    const getAllExchangeRates = async() : Promise<ExchangeRateEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
        
    }

    const getExchangeRateByKey = async(key: string): Promise<ExchangeRateEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewExchangeRate = async(newData: ExchangeRateEntity) : Promise<ExchangeRateEntity> => {
        const result = await axiosPostData(referenceAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Charge Type Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Charge Type ${newData.ExchangeRate} Sub Charge Type ${newData.subExchangeRate}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateExchangeRate = async(updatedData: ExchangeRateEntity): Promise<ExchangeRateEntity> => {
        const result = await axiosPutData(referenceAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteExchangeRateByKey = async(key: string): Promise<boolean> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const getAllCurrencies = async(): Promise<CurrencyEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"])?.map((code) => {
                const currency:CurrencyEntity = {
                    currencyCode: code
                }
                return currency;
            });
        }).catch(err => {
            return [];
        })
    }

    return {
        getAllExchangeRates: getAllExchangeRates,
        getExchangeRateByKey: getExchangeRateByKey,
        createNewExchangeRate: createNewExchangeRate,
        updateExchangeRate: updateExchangeRate,
        deleteExchangeRateByKey: deleteExchangeRateByKey,
        getAllCurrencies: getAllCurrencies
    }
}