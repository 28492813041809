import { SelectionChangedEvent } from "ag-grid-community";
import { ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { INITIAL_NON_OPS_DTL_COL_DEF, transferRowData } from "presentation/constant/NonOps/NonOpsDtlColumnDefinition";
import { useNonOpsDetailVM } from "presentation/hook/NonOps/useNonOpsDetailVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useNonOpsDetailTracked } from "presentation/store/NonOps/NonOpsDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const NonOpsDetailTablePanel: React.FC = () => {
    const [nonOpsDetailState] = useNonOpsDetailTracked();
    const nonOpsDetailVM = useNonOpsDetailVM();
    const { isRead, isShowDetailInfo } = nonOpsDetailState.viewState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowCreate } = anainfoState;
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = (INITIAL_NON_OPS_DTL_COL_DEF?.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        nonOpsDetailVM.updateSelectedManualChargeDetails(selectedRows);
    }, [nonOpsDetailVM])

    const handleRowClick = useCallback((manualChargeDetail: ManualChargeDetailEntity) => {
        nonOpsDetailVM.onRowClick(manualChargeDetail);
    }, [nonOpsDetailVM])

    const handleDelete = useCallback(async (manualChargeDetail: ManualChargeDetailEntity) => {
    }, [])

    const handleAddClick = useCallback(async () => {
        nonOpsDetailVM.onAdd();
        nonOpsDetailVM.onInitDefaultValue();
    }, [nonOpsDetailVM])

    const memoNonOpsDetailTable = useMemo(() => {
        return <NbisTable
            id='NonOpsDetail-table'
            columns={INITIAL_NON_OPS_DTL_COL_DEF?.slice()}
            // headerLabel={NON_OPS_DTL_CONS.NON_OPS_DTL}
            //onRowDoubleClick={(e: any, manualChargeDetail: ManualChargeDetailEntity) => handleRowDoubleClick(manualChargeDetail)}
            onRowClick={(e: any) => handleRowClick(e.data)}
            data={transferRowData(nonOpsDetailState.manualChargeDetails) || []}
            showPaginator={false}
            editable={false}
            showAddIcon={allowCreate && !isRead && !isShowDetailInfo}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            onAddClick={handleAddClick}
            handleSelectionChanged={handleSelectionChange}
            onDelete={(deleteManualChargeDetail: ManualChargeDetailEntity) => handleDelete(deleteManualChargeDetail)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef} />;
    }, [nonOpsDetailState.manualChargeDetails, isRead, isShowDetailInfo, handleAddClick, handleSelectionChange, handleRowClick, handleDelete, allowCreate])


    if (nonOpsDetailState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{<TableWrapper>{memoNonOpsDetailTable}</TableWrapper>}</>;
}

export default memo(NonOpsDetailTablePanel);