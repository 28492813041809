import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import moment from "moment";
import { EMPTY_NON_OPS_SEARCH_CRITERIA } from "presentation/constant/NonOps/NonOpsSearchCriteria";
import { useNonOpsHeaderVM } from "presentation/hook/NonOps/useNonOpsHeaderVM";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import NonOpsHeaderSearchPanel from "./LeftPanel/NonOpsHeaderSearchPanel";
import NonOpsHeaderTitleBar from "./NonOpsHeaderTitleBar";
import NonOpsHeaderTablePanel from "./Table/NonOpsHeaderTablePanel";
import { DEFAULT_DATE_RANGE } from "domain/repository/apiUtil";


const NonOpsHeaderMaintenance:React.FC = () => {
    const [ nonOpsHdrState ] = useNonOpsHeaderTracked();
    const { isShowCriteriaPanel,isAllowAutoSearch,isBackFromDetail,searchCriteria } = nonOpsHdrState;
    const nonOpsHdrVM = useNonOpsHeaderVM();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                const results = await Promise.allSettled([
                    nonOpsHdrVM.loadDropdownOption(),
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
            }
        }
        isAllowAutoSearch && initialScreen().then((data)=>{
            let newSearchCriteria = null;
            if(isBackFromDetail){
                newSearchCriteria = {...searchCriteria}
            }else{
                newSearchCriteria = { 
                    ...EMPTY_NON_OPS_SEARCH_CRITERIA, 
                    createdDateFrom: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(), 
                    createdDateTo: moment().endOf("day").toDate(),
                };           
            }
            nonOpsHdrVM.onSearch(newSearchCriteria,EMPTY_SEARCH_MODE_ENTITY)
        }).then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [isAllowAutoSearch, isBackFromDetail, nonOpsHdrVM, searchCriteria])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <NonOpsHeaderTitleBar/>

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<NonOpsHeaderSearchPanel/>}
                rightChildren={<NonOpsHeaderTablePanel/>} 
                />
        </div>
    </>
}

export default memo(NonOpsHeaderMaintenance);