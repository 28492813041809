import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { billingCycleDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { NonOpsConstant, nonOpsHdrRequiredFieldList, nonOpsHdrStdBillRequiredFieldList } from "presentation/constant/NonOps/NonOpsConstant";
import { nonOpsTypes } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { useNonOpsDetailVM } from "presentation/hook/NonOps/useNonOpsDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { DropdownProps } from "presentation/model/DropdownProps";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useNonOpsDetailTracked } from "presentation/store/NonOps/NonOpsDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHGroupRadioButton, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const NonOpsHeaderFieldFormPanel = () => {  
    const [ anainfoState ] = useANAInfoTracked();
    const nonOpsDetailVM = useNonOpsDetailVM();
    const [ nonOpsDetailState ] = useNonOpsDetailTracked();
    const messageBarVM = useMessageBarVM();    
    
    const { isAdd, isEditable, isSaveClicked, isRead, editingHeader, allFormState, isShowDetailInfo, isAdjustmentRead} = nonOpsDetailState.viewState;
    const { allowUpdate } = anainfoState;
    const {currentManualChargeHeader, manualChargeDetails} = nonOpsDetailState;

    const currentManChgHdr = isRead?currentManualChargeHeader: editingHeader;
    const [isLoading, setIsLoading] = useState(false);
    
    const HDR_CONST = NonOpsConstant.Header;
    
    let saveEnabled:boolean = false;

    if (!((isAdd && manualChargeDetails.length>0 && !isShowDetailInfo) || (isEditable && !isRead && manualChargeDetails.length>0 && !isShowDetailInfo))) {
        saveEnabled = true;
    }

    const isEditDisable = () => {
        if (!(currentManualChargeHeader.hdrState === ChargeConstantCollection.manHdrState.NIL 
            || currentManualChargeHeader.hdrState === ChargeConstantCollection.manHdrState.STD)) return true;

        if(nonOpsDetailState.manualChargeDetails && nonOpsDetailState.manualChargeDetails.length > 0){
            const adjType = nonOpsDetailState.manualChargeDetails[0].adjType;
            if(adjType !== ChargeConstantCollection.adjType.NORMAL &&
                adjType !== ChargeConstantCollection.adjType.CANCEL_REBILL &&                
                adjType !== ChargeConstantCollection.adjType.ADJUSTMENT
            ) {
                return true;
            }            
        }

        return false;
    }        
    
    // const handleClose = useCallback(() => {
    //     nonOpsDetailVM.onCloseScreen();
    // },[nonOpsDetailVM])

    const handleEdit = useCallback(() => {
        nonOpsDetailVM.onEdit(currentManChgHdr, manualChargeDetails);
    },[currentManChgHdr, manualChargeDetails, nonOpsDetailVM])

    const handleSave = useCallback(() => {
        setIsLoading(true);
        
        nonOpsDetailVM.onSaveClicked();
        nonOpsDetailVM.onSave(editingHeader,manualChargeDetails,isAdd).then((res) => {
            if(res && res.id){
                setIsLoading(false);
                nonOpsDetailVM.onSearch(res.id as number).then(()=>{
                        setIsLoading(false);
                    }).catch((error) => {                    
                        setIsLoading(false);
                    });
            }else{
                setIsLoading(false);
                if(allFormState && allFormState["mandatoryCheckFail"]){
                    messageBarVM.showError(allFormState["mandatoryCheckFail"]?.toString());
                }               
            }
            
        }).catch(()=>{
            setIsLoading(false);
            messageBarVM.showError('Save Data Failed.');
        });
        
        
    },[allFormState, editingHeader, isAdd, manualChargeDetails, messageBarVM, nonOpsDetailVM])

    useEffect(()=>{
        const refreshCustomers = async()=>{
            nonOpsDetailVM.onCompanyItemChanged(currentManChgHdr.billToCompany);
        }        
        refreshCustomers();
    },[currentManChgHdr.billToCompany, nonOpsDetailVM])

    useEffect(()=>{
        const refreshCustomerName = async()=>{
            nonOpsDetailVM.onCustomerItemChanged(currentManChgHdr.customerCode);
        }        
        refreshCustomerName();
    },[currentManChgHdr.customerCode, nonOpsDetailVM])

    const memoNonOpsTypeGroup = useMemo(() =>
        <div className='im-flex-row-item'>
           <HPHGroupRadioButton
                label="Non-Ops Type"                
                disabled={!!currentManChgHdr.id}
                radioOptions={nonOpsTypes}
                value={currentManChgHdr.nonOpsType??''}
                onChange={(e) => nonOpsDetailVM.onRadioChange(e,'nonOpsType')}
                checked={nonOpsTypes.find(item => item.key === currentManChgHdr.nonOpsType)?.name}
                />
        </div>
    , [currentManChgHdr.id, currentManChgHdr.nonOpsType, nonOpsDetailVM])

    // const memoState = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='180px'
    //             maxLength={100}
    //             label={HDR_CONST.STATE}
    //             type="text"
    //             value={currentManChgHdr?.hdrState || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [currentManChgHdr?.hdrState, HDR_CONST.STATE])

    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentManChgHdr?.hdrState || ''}
                fieldValue={currentManChgHdr?.hdrState}
                fieldLabel={HDR_CONST.STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrState'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.STATE, allFormState, currentManChgHdr?.hdrState, isSaveClicked])
    
    // const memoRefNo = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='180px'
    //             maxLength={100}
    //             label={HDR_CONST.REF_NO}
    //             type="text"
    //             value={currentManChgHdr?.manChargeNo || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [currentManChgHdr?.manChargeNo, HDR_CONST.REF_NO])


    const memoRefNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentManChgHdr?.manChargeNo || ''}
                fieldValue={currentManChgHdr?.manChargeNo}
                fieldLabel={HDR_CONST.REF_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'manChargeNo'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.REF_NO, allFormState, currentManChgHdr?.manChargeNo, isSaveClicked])

    const memoServDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead || isAdjustmentRead}
                    label={HDR_CONST.SERV_DATE}
                    width="150px"
                    date={currentManChgHdr?.stdBillServiceDate}
                    fieldName="stdBillServiceDate"
                    errorMessage={allFormState?allFormState["stdBillServiceDate"]:""}
                    onDateChange={nonOpsDetailVM.onHeaderFieldChange}/>
        </div>
    , [isRead, isAdjustmentRead, HDR_CONST.SERV_DATE, currentManChgHdr?.stdBillServiceDate, allFormState, nonOpsDetailVM.onHeaderFieldChange])

    const memoEffDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead || currentManChgHdr.nonOpsType === 'NONOPS' || isAdjustmentRead}
                    label={HDR_CONST.EFF_DATE}
                    width="150px"
                    date={currentManChgHdr?.stdBillEffectiveDate??''}
                    fieldName="stdBillEffectiveDate"
                    errorMessage={allFormState?(allFormState["validateRangeDate"]?allFormState["validateRangeDate"]:
                        allFormState["stdBillEffectiveDate"]):""}
                    onDateChange={nonOpsDetailVM.onHeaderFieldChange}/>
        </div>
    , [isRead, currentManChgHdr.nonOpsType, currentManChgHdr?.stdBillEffectiveDate, isAdjustmentRead, HDR_CONST.EFF_DATE, allFormState, nonOpsDetailVM.onHeaderFieldChange])

    const memoExpDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead || currentManChgHdr.nonOpsType === 'NONOPS' || isAdjustmentRead}
                    label={HDR_CONST.EXP_DATE}
                    width="150px"
                    date={currentManChgHdr?.stdBillExpiryDate}
                    fieldName="stdBillExpiryDate"
                    errorMessage={""}
                    onDateChange={nonOpsDetailVM.onHeaderFieldChange}/>
        </div>
    , [isRead, currentManChgHdr.nonOpsType, currentManChgHdr?.stdBillExpiryDate, isAdjustmentRead, HDR_CONST.EXP_DATE, nonOpsDetailVM.onHeaderFieldChange])
    
    const memoBillingCycle = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || currentManChgHdr.nonOpsType === 'NONOPS' || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.stdBillBillingCycle || ''}
                fieldValue={currentManChgHdr?.stdBillBillingCycle}
                fieldLabel={HDR_CONST.BILLING_CYCLE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'stdBillBillingCycle'}
                maxLength={60}
                requiredFieldList={currentManChgHdr.nonOpsType === 'NONOPS'?nonOpsHdrRequiredFieldList:nonOpsHdrStdBillRequiredFieldList}
                options={billingCycleDropdownOption?.filter((item:DropdownProps) => item.value !== 'ONE_OFF')}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
        </div>
    , [HDR_CONST.BILLING_CYCLE, allFormState, currentManChgHdr.nonOpsType, currentManChgHdr?.stdBillBillingCycle, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailVM])

    // const memoTxtBillingCycle = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='180px'
    //             maxLength={100}
    //             label={HDR_CONST.BILLING_CYCLE}
    //             type="text"
    //             value={currentManChgHdr?.stdBillBillingCycle || ''} 
    //             disabled={true}
    //             />
    //     </div>
    // , [HDR_CONST.BILLING_CYCLE, currentManChgHdr?.stdBillBillingCycle])
    
    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.operatingTml || ''}
                fieldValue={currentManChgHdr?.operatingTml}
                fieldLabel={HDR_CONST.OPERATING_TML}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                options={nonOpsDetailState.dynamicOptions.operatingTmlDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [HDR_CONST.OPERATING_TML, allFormState, currentManChgHdr?.operatingTml, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.operatingTmlDropdownOptions, nonOpsDetailVM])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.currencyCode || ''}
                fieldValue={currentManChgHdr?.currencyCode}
                fieldLabel={HDR_CONST.CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                options={nonOpsDetailState.dynamicOptions.currencyDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [HDR_CONST.CURRENCY, allFormState, currentManChgHdr?.currencyCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.currencyDropdownOptions, nonOpsDetailVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.billToCompany || ''}
                fieldValue={currentManChgHdr?.billToCompany}
                fieldLabel={HDR_CONST.COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                options={nonOpsDetailState.dynamicOptions.billToCompDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
        </div>
    , [HDR_CONST.COMPANY_CODE, allFormState, currentManChgHdr?.billToCompany, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.billToCompDropdownOptions, nonOpsDetailVM])

    const memoCustCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"600px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.customerCode || ''}
                fieldValue={currentManChgHdr?.customerCode}
                fieldLabel={HDR_CONST.CUST_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                options={nonOpsDetailState.dynamicOptions.customerCodeDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
        </div>
    , [HDR_CONST.CUST_CODE, allFormState, currentManChgHdr?.customerCode, isAdjustmentRead, isRead, isSaveClicked, nonOpsDetailState.dynamicOptions.customerCodeDropdownOptions, nonOpsDetailVM])

    const memoCustName = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"600px", marginBottom:"24px"}}>
            {/* <InputField  
                width='320px'
                maxLength={100}
                label={HDR_CONST.CUST_NAME}
                type="text"
                value={currentManChgHdr?.customerName || ''} 
                disabled={true}
                /> */}

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.customerName || ''}
                fieldValue={currentManChgHdr?.customerName}
                fieldLabel={HDR_CONST.CUST_NAME}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'customerName'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={()=>{}} /> 
        </div>
    , [HDR_CONST.CUST_NAME, allFormState, currentManChgHdr?.customerName, isSaveClicked])

    const memoSubject = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.subject || ''}
                fieldValue={currentManChgHdr?.subject}
                fieldLabel={HDR_CONST.SUBJECT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'subject'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.subject, HDR_CONST.SUBJECT, isSaveClicked, nonOpsDetailVM])

    const memoChargeDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.chargeDesc || ''}
                fieldValue={currentManChgHdr?.chargeDesc}
                fieldLabel={HDR_CONST.CHG_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeDesc'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.chargeDesc, HDR_CONST.CHG_DESC, isSaveClicked, nonOpsDetailVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.remarks || ''}
                fieldValue={currentManChgHdr?.remarks}
                fieldLabel={HDR_CONST.REMARK}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'remarks'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.remarks, HDR_CONST.REMARK, isSaveClicked, nonOpsDetailVM])

    const memoYourRef = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.yourRef || ''}
                fieldValue={currentManChgHdr?.yourRef}
                fieldLabel={HDR_CONST.YOUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'yourRef'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.yourRef, HDR_CONST.YOUR_REF, isSaveClicked, nonOpsDetailVM])

    const memoOurRef = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.ourRef || ''}
                fieldValue={currentManChgHdr?.ourRef}
                fieldLabel={HDR_CONST.OUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'ourRef'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.ourRef, HDR_CONST.OUR_REF, isSaveClicked, nonOpsDetailVM])

    
    const memoOrderNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || currentManChgHdr.nonOpsType === 'STDBILL' || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.orderNo || ''}
                fieldValue={currentManChgHdr?.orderNo}
                fieldLabel={HDR_CONST.ORDER_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'orderNo'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, currentManChgHdr.nonOpsType, currentManChgHdr?.orderNo, isAdjustmentRead, HDR_CONST.ORDER_NO, isSaveClicked, nonOpsDetailVM])

    // const memoTxtOrderNo = useMemo(() =>
    //     <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
    //         <InputField  
    //             width='180px'
    //             maxLength={100}
    //             label={HDR_CONST.ORDER_NO}
    //             type="text"
    //             value={currentManChgHdr?.orderNo || ''} 
    //             disabled={true}
    //             onChange={()=>{}}
    //             />
    //     </div>
    // , [HDR_CONST.ORDER_NO, currentManChgHdr?.orderNo])

    const memoDesc1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"600px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.hdrDesc1 || ''}
                fieldValue={currentManChgHdr?.hdrDesc1}
                fieldLabel={HDR_CONST.HDR_DESC1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrDesc1'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.hdrDesc1, HDR_CONST.HDR_DESC1, isSaveClicked, nonOpsDetailVM])



    const memoDesc2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"600px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.hdrDesc2 || ''}
                fieldValue={currentManChgHdr?.hdrDesc2}
                fieldLabel={HDR_CONST.HDR_DESC2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrDesc2'}
                maxLength={60}
                requiredFieldList={nonOpsHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    nonOpsDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.hdrDesc2, HDR_CONST.HDR_DESC2, isSaveClicked, nonOpsDetailVM])

    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{currentManChgHdr.manChargeNo??'Header'}</SidebarTitle>
                        <SidebarActionCross>
                                    {(!isAdd && (isEditable && isRead)) &&
                                        <>
                                            {
                                                (
                                                    (isEditable && allowUpdate)
                                                )
                                                && <IconButton fileName='Icon-pen' size='medium' disabled={isEditDisable()} toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                            }
                                        </>}
                                        {/*<IconButton 
                                            fileName="Icon-cross" 
                                            size="medium" 
                                            disabled={!isRead && isShowDetailInfo} 
                                            toolTipArrow={false} 
                                            toolTipPlacement="left" 
                                            toolTipText={isAdd || isRead ? 'Close' : 'Cancel'} 
                                            onClick={isAdd || isRead ? handleClose : handleCancel} />*/}
                                    {((isAdd || (isEditable && !isRead))) &&
                                        <>
                                        {/* <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} /> */}
                                            {/* {
                                                (
                                                    (isAdd) ||
                                                    (isEditable && !isRead)
                                                )
                                                && <IconButton fileName="Icon-cross" size="medium" disabled={cancelEnabled} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Cancel'} onClick={() => handleCancel(currentManChgHdr)} />                                                
                                            } */}
                                            {
                                                (
                                                    (isAdd) ||
                                                    (isEditable && !isRead)
                                                )
                                                && <IconButton fileName="Icon-tick" size="medium" disabled={saveEnabled} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={handleSave} />
                                            }
                                        </>}
                                </SidebarActionCross>

                    </Sidebarheader>
                </div>
            </div> 
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <>
            <div className={'add-edit-form'} style={{ height:'78vh', maxHeight:'78vh' , overflow: 'auto'}}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <CriteriaItemContainer>
                    {memoNonOpsTypeGroup}
                    </CriteriaItemContainer>      
                    <CriteriaItemContainer>
                    {memoState} {memoRefNo} {memoBillToCompany}
                    </CriteriaItemContainer>         
                    <CriteriaItemContainer>
                    {memoServDate} {memoEffDate} {memoExpDate}
                    </CriteriaItemContainer>       
                    <CriteriaItemContainer>
                    {memoOperatingTml} {memoBillingCycle} {memoCurrency}
                    </CriteriaItemContainer>               
                        
                    <CriteriaItemContainer>
                        {memoCustCode}
                        {/* <HPHButton label={'Select Customer'} size={'Small'} theme={'Secondary'} onClick={handleSelectCustomerCode} />       */}
                    </CriteriaItemContainer>       
                    <CriteriaItemContainer>
                        {memoCustName}
                    </CriteriaItemContainer>                
                    <CriteriaItemContainer>
                        {memoDesc1}
                    </CriteriaItemContainer>                    
                    <CriteriaItemContainer>
                        {memoDesc2}
                    </CriteriaItemContainer>           
                    <CriteriaItemContainer>
                        {memoSubject}
                        {memoChargeDesc}
                        {memoOrderNo}
                    </CriteriaItemContainer>       
                    <CriteriaItemContainer>
                        {memoYourRef}{memoOurRef}{memoRemarks}
                    </CriteriaItemContainer>     
                </div>                  
            </div>
        </div>
            </>
        </div>
    </>

}
